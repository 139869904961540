import {ComponentType, createElement} from 'react';
import {Subtract} from 'utility-types';
import {useAuthentication, UseAuthentication} from '../hooks/useAuthentication';

export const withAuthentication = () => <TProps extends UseAuthentication = any>(
    WrappedComponent: ComponentType<TProps>
) => {
    const handler = (props: Subtract<TProps, UseAuthentication>) => {
        const { auth } = useAuthentication();

        const renderedProps: TProps = {
            auth,
            ...props,
        } as TProps;

        return createElement<TProps>(WrappedComponent, renderedProps);
    };

    const name = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    handler.displayName = `withAuthentication(${name})`;
    handler.WrappedComponent = WrappedComponent;

    return handler;
};