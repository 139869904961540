import {ComponentType, createElement} from 'react';
import {Subtract} from 'utility-types';
import {UseAuthentication} from '../hooks/useAuthentication';
import {UseAxios, useAxios} from '../hooks/useAxios';
import {withAuthentication} from './withAuthentication';

export const withAxios = () => <TProps extends UseAxios = any>(
    WrappedComponent: ComponentType<TProps>
) => {
    const handler = (props: Subtract<TProps, UseAxios> & UseAuthentication) => {
        const { axios, baseURL } = useAxios();

        const renderedProps: TProps = {
            axios,
            baseURL,
            ...props,
        } as TProps;

        return createElement<TProps>(WrappedComponent, renderedProps);
    };

    const name = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    handler.displayName = `withAxios()(${name})`;
    handler.WrappedComponent = WrappedComponent;

    return withAuthentication()(handler);
};
