import {useContext} from 'react';
import {Auth} from '../auth/Auth';
import {AuthContext} from '../auth/AuthContext';

export interface UseAuthentication {
    auth?: Auth;
}

export function useAuthentication() : UseAuthentication {
    const authContext = useContext(AuthContext);
    const { auth } = authContext;

    return {
        auth
    } as UseAuthentication;
}