import {ComponentType} from 'react';
import {Location, NavigateFunction, Params, useLocation, useNavigate, useParams,} from 'react-router-dom';
import {Subtract} from 'utility-types';

interface WithRouterProps<ParamsOrKey extends string | Record<string, string | undefined> = string> {
    location: Location;
    navigate: NavigateFunction;
    params: Readonly<[
        ParamsOrKey
    ] extends [string] ? Params<ParamsOrKey> : Partial<ParamsOrKey>>;
}

function withRouter<TProps extends WithRouterProps = any, ParamsOrKey extends string | Record<string, string | undefined> = string>(
    Component: ComponentType<TProps>
) {
    function ComponentWithRouterProp(props: Subtract<TProps, WithRouterProps>) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams<ParamsOrKey>();
        const renderedProps = {
            ...props as TProps,
            location, navigate, params
        } as TProps;

        return <Component {...renderedProps as TProps} />;
    }

    return ComponentWithRouterProp;
}

export { withRouter };
export type { WithRouterProps };
