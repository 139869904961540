import {useTranslation} from 'react-i18next';
import LoadingIndicator from '../../components/LoadingIndicator';
import ProductionUnits from '../../components/ProductionUnits';
import {Companies} from './Companies';
import {Header} from './Header';
import {useHomeHooks} from './Home.hooks';
import './Home.css';

function Home() {
    const { t } = useTranslation();
    const { companies, productionUnits, reloadCompanies, reloadProductionUnits } = useHomeHooks();

    if (companies === undefined || productionUnits === undefined) {
        return ( <LoadingIndicator />);
    }

    return (
        <div className='container-page'>
            <Header t={t} />
            <Companies t={t} clients={companies} onDeleteSuccess={reloadCompanies} />
            <ProductionUnits productionUnits={productionUnits} onDeleteSuccess={reloadProductionUnits} />
        </div>
    );
}

export { Home };
