import {
    ComponentClass,
    ForwardedRef,
    forwardRef,
    ForwardRefRenderFunction,
    FunctionComponent,
    PropsWithChildren,
    RefObject
} from 'react';

interface WithForwardedRefComponent<T> extends ComponentClass<T>, FunctionComponent<T> {
    forwardedRef?: RefObject<T>
}

export const withForwardedRef = () => function <T, P extends Record<string, unknown> = any>(Component: WithForwardedRefComponent<P>) {
    const handle: ForwardRefRenderFunction<T, P> = (props: PropsWithChildren<P>, ref: ForwardedRef<T>) => <Component {...props as P} forwardedRef={ref} />;

    const name = Component.displayName || Component.name;
    handle.displayName = `withForwardedRef(${name})`;


    return forwardRef(handle);
};
