import {createContext, FunctionComponent, ReactElement, ReactNode} from 'react';
import {Auth} from './Auth';

interface IAuthContext {
    auth?: Auth
}

interface IAuthContextProvider {
    auth?: Auth
    children?: ReactNode,
}

const AuthContext = createContext<IAuthContext>({ auth: undefined } as IAuthContext);

const AuthProvider: FunctionComponent<IAuthContextProvider> = ({ children, auth }: IAuthContextProvider) : ReactElement<any, any> | null => {
    const state = { auth };

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };