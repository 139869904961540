import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    AddClientFileVm,
    ClientByMonikerVm,
    ClientFilesClient,
    ClientsClient,
    ClientsVm
} from '../../api/vincotte.via.api';
import {ClientFilesService} from '../../services/ClientFilesService';
import {AddFileOnFailureHandler, AddFileOnProgressHandler, File, FileUploadAdapter} from '../../components/FileUpload';
import {useAxiosService} from '../useAxiosService';
import {useClientQuery} from '../useClientQuery';
import {useFileUploadProgress} from '../useFileUploadProgress';

interface UseCompaniesProps {
    includeProductionLines?: boolean;
}

interface UseCompanies {
    companies?: Array<ClientsVm> | undefined;
    reload: (clean?: boolean | undefined ) => void;
}

const useCompanies = ({ includeProductionLines = false }: UseCompaniesProps) : UseCompanies => {
    const navigate = useNavigate();

    const {
        data: companies,
        reload
    } = useClientQuery({
        service: ClientsClient,
        methodName: 'getClients'
    }, includeProductionLines);

    if (companies && companies.length === 1) {
        const company = companies.pop();
        navigate(`/company/${company?.moniker}`);
    }

    return {
        companies,
        reload
    };
};

interface UseCompanyProps {
    moniker?: string;
    includeProductionLines?: boolean;
}

interface UseCompany {
    company?: ClientByMonikerVm | undefined;
    reload: (clean?: boolean | undefined ) => void;
}

const useCompany = ({ moniker, includeProductionLines = false }: UseCompanyProps) : UseCompany => {
    const {
        data: company,
        reload
    } = useClientQuery({
        service: ClientsClient,
        methodName: 'getClient',
        beforeLoad: (moniker) => moniker.length >= 0
    }, moniker || '', includeProductionLines);

    return {
        company,
        reload
    };
};

interface UseFileUploadAdapter {
    // uploadAdapter?: FileUploadAdapter<AddClientFileVm> | undefined;
    addFile: (moniker: string, file: globalThis.File) => Promise<AddClientFileVm | Error>
}

function useFileUploadAdapter() : UseFileUploadAdapter {
    const { service: clientFilesClient } = useAxiosService(ClientFilesClient);
    const { uploadProgress } = useFileUploadProgress();

    const clientFilesService = useMemo(() => new ClientFilesService(clientFilesClient), []);

    // const uploadAdapter = useMemo<FileUploadAdapter<AddClientFileVm> | undefined>(() => {
    //     const addFile = (file: File, abortSignal: AbortSignal, onProgress?: AddFileOnProgressHandler, onFailure?: AddFileOnFailureHandler) => (
    //         clientFilesService.addFile(moniker || '', file, abortSignal, onProgress, onFailure)
    //     );

    //     const adapter = new FileUploadAdapter<AddClientFileVm>(addFile);
    //     adapter.fileUploadProgressController = uploadProgress;
    // }, [ moniker ]);

    function addFile(moniker: string, file: globalThis.File) {
        const addFile = (file: File, abortSignal: AbortSignal, onProgress?: AddFileOnProgressHandler, onFailure?: AddFileOnFailureHandler) => (
            clientFilesService.addFile(moniker, file, abortSignal, onProgress, onFailure)
        );

        const adapter = new FileUploadAdapter<AddClientFileVm>(addFile);
        adapter.fileUploadProgressController = uploadProgress;

        return adapter.addFile(File.fromFile(file));
    }

    return {
        // uploadAdapter,
        addFile
    };
}

export { useCompanies, useCompany, useFileUploadAdapter };
