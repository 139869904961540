import {useCallback, useEffect, useState} from 'react';
import {IService, useAxiosService} from './useAxiosService';
import {ApiException} from '../api/vincotte.via.api';

export enum ClientyQueryStatus {
    NONE = 'none',
    LOADING = 'loading',
    ERROR = 'error',
    SUCCESS = 'success',
}

interface UseClientCallProps<
    TService extends Record<keyof TService, (...args: any) => any>,
    TMethod extends keyof TService,
    TArgs extends Parameters<TService[TMethod]> = Parameters<TService[TMethod]>
> {
    service: IService<TService>
    methodName: TMethod
    beforeLoad?: (...args: TArgs) => boolean;
    dependencyList?: Array<boolean>;
}

export interface ClientyQueryInfo {
    isLoading: boolean;
    hasError: boolean;
    error?: ApiException;
    status: ClientyQueryStatus;
}

export interface UseClientCall<
    TService extends Record<keyof TService, (...args: any) => any>,
    TMethod extends keyof TService,
    TReturn = Awaited<ReturnType<TService[TMethod]>>>
    extends ClientyQueryInfo {
    service: TService;
    data: TReturn | undefined;
    reload: ReloadQuery;
}

export function useClientQuery<
    TService extends Record<keyof TService, (...args: any) => any>,
    TMethod extends keyof TService,
    TArgs extends Parameters<TService[TMethod]> = Parameters<TService[TMethod]>,
    TReturn extends Awaited<ReturnType<TService[TMethod]>> = Awaited<ReturnType<TService[TMethod]>>
>({
    service,
    methodName,
    beforeLoad,
    dependencyList = [],
}: UseClientCallProps<TService, TMethod>, ...args: TArgs) : UseClientCall<TService, TMethod> {
    type ServiceType = (...args: TArgs) => TReturn;

    const { service: instance } = useAxiosService<TService>(service);

    const [ data, setData ] = useState<TReturn | undefined>(undefined);

    const [ status, setStatus ] = useState<ClientyQueryStatus>(ClientyQueryStatus.NONE);

    const [ error, setError ] = useState<ApiException | undefined>(undefined);

    const load = (...params: TArgs) : TReturn =>
        (instance[methodName] as ServiceType)(...params).then((data: TReturn) => {
            setData(data);
            setStatus(ClientyQueryStatus.SUCCESS);

            return;
        });

    const reload = useCallback((clean: boolean | undefined = false) => {
        setStatus(ClientyQueryStatus.LOADING);
        setError(undefined);

        if (clean) {
            setData(undefined);
        }

        load(...args);
    }, args);

    useEffect(() => {
        if (!dependencyList.every(Boolean)) {
            return;
        }


        if (beforeLoad && !beforeLoad(...args)) {
            return;
        }

        setStatus(ClientyQueryStatus.LOADING);

        const abortController = new AbortController();
        const argsWithSignal = [...args, abortController.signal] as TArgs;

        load(...argsWithSignal).catch((err: ApiException) => {
            if (!abortController.signal.aborted) {
                setStatus(ClientyQueryStatus.ERROR);
                setError(err);
            }
        });

        return () => {
            abortController.abort();
            setData(undefined);
        };
    }, [...args, ...dependencyList]);

    return {
        data,
        service: instance,
        reload,
        status,
        isLoading: status === ClientyQueryStatus.LOADING,
        hasError: status === ClientyQueryStatus.ERROR,
        error
    };

}