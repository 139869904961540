import {ProductionLinesClient, ProductionLinesVm, ProductionLineVm2} from '../../api/vincotte.via.api';
import {useClientQuery} from '../useClientQuery';

interface UseProductionUnits {
    productionUnits?: Array<ProductionLinesVm> | undefined;
    reload: (clean?: boolean | undefined) => void;
}

const useProductionUnits = () : UseProductionUnits => {
    const {
        data: productionUnits,
        reload
    } = useClientQuery<ProductionLinesClient, 'getProductionLines'>({
        service: ProductionLinesClient,
        methodName: 'getProductionLines',
    });

    return {
        productionUnits,
        reload
    };
};

interface UseProductionProps {
    id?: number;
}

interface UseProductionUnit {
    productionUnit?: ProductionLineVm2 | undefined;
    reload: (clean?: boolean | undefined) => void;
}

const useProductionUnit = ({ id }: UseProductionProps) : UseProductionUnit => {
    const {
        data: productionUnit,
        reload
    } = useClientQuery<ProductionLinesClient, 'getProductionLine'>({
        service: ProductionLinesClient,
        methodName: 'getProductionLine',
        beforeLoad: (puId) => puId > 0,
    }, id || 0 );

    return {
        productionUnit,
        reload
    };
};

export { useProductionUnit, useProductionUnits };
