import {WithTranslation} from 'react-i18next';
import {withPureComponent} from '../../HOC/withPureComponent';
import PageTitle from '../../components/PageTitle';

type HeaderProps = Pick<WithTranslation, 't'>;

function Header(props: HeaderProps) {
    const { t } = props;

    return (
        <div className="row no-gutters pb-2">
            <div className='col-10'>
                <PageTitle logoSrc='/images/vincotte-logo.svg' title={t('pages.Home.Admin')} />
                {/* <p>{t('pages.Home.See here all your current inspections, available production units')}</p> */}
            </div>

            <div className='col-2'></div>
        </div>
    );
}

const HeaderPureComponent = withPureComponent()(Header);

export { HeaderPureComponent as Header };
