import {ComponentType, createElement} from 'react';
import {Subtract} from 'utility-types';
import {useFileUploadProgress, UseFileUploadProgressProps} from '../hooks/useFileUploadProgress';

export const withFileUploadProgress = () => <TProps extends UseFileUploadProgressProps = UseFileUploadProgressProps>(WrappedComponent: ComponentType<TProps>) => {
    const handler = (props: Subtract<TProps, UseFileUploadProgressProps>) => {
        const { uploadProgress } = useFileUploadProgress();

        const renderedProps : TProps = {
            ...props,
            uploadProgress,
        } as TProps;

        return createElement<TProps>(WrappedComponent, renderedProps);
    };

    const name = WrappedComponent.displayName || WrappedComponent.name;
    handler.displayName = `withFileUploadProgress(${name})`;
    handler.WrappedComponent = WrappedComponent;

    return handler;
};