import {useAuth0} from '@auth0/auth0-react';
import {useAuthentication} from './useAuthentication';

export interface UseAuthorization {
    isAuthenticated: () => boolean;
    isAuthorized: () => boolean;
    // hasScopes: () => boolean;
    hasPermissions: () => boolean;
    hasSomePermission: () => boolean;
    login: () => void;
}

export interface UseAuthorizationOptions {
    permissions?: Array<string>;
    somePermission?: Array<string>;
    scopes?: Array<string>;
}

export function useAuthorization(authorization: UseAuthorizationOptions) : UseAuthorization {

    const { auth } = useAuthentication();
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const {
        permissions: internalPermissions = new Array<string>(),
        somePermission: internalSomePermission = new Array<string>(),
        scopes: internalScopes = new Array<string>(),
    } = authorization;

    // const hasScopes = (scopes: Array<string> = []) => {
    //     const s = [...scopes, ...internalScopes];
    //     return s.length === 0 || (auth && auth.userHasScopes(s));
    // };

    const hasPermissions = (permissions: Array<string> = []) => {
        const p = [...permissions, ...internalPermissions];
        return p.length === 0 || (auth && auth.userHasPermissions(p));
    };

    const hasSomePermission = (somePermission: Array<string> = []) => {
        const sp = [...somePermission, ...internalSomePermission];
        return sp.length === 0 || (auth && auth.userHasSomePermission(sp));
    };

    const isAuthorized = (scopes: Array<string> = [], permissions: Array<string> = [], somePermission: Array<string> = []) => {
        return /* hasScopes(scopes) && */ hasPermissions(permissions) && hasSomePermission(somePermission);
    };

    const isLoggedIn = () => isAuthenticated;
    const login =loginWithRedirect;

    return {
        isAuthenticated: isLoggedIn,
        isAuthorized,
        // hasScopes,
        hasPermissions,
        hasSomePermission,
        login,
    } as UseAuthorization;
}