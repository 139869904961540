import {ClientsVm, ProductionLinesVm} from '../../api/vincotte.via.api';
import {useCompanies} from '../../hooks/Queries/Company.hooks';
import {useProductionUnits} from '../../hooks/Queries/ProductionUnit.hooks';

interface UseHomeHooks {
    companies: Array<ClientsVm> | undefined;
    productionUnits: Array<ProductionLinesVm> | undefined
    reloadCompanies: () => void;
    reloadProductionUnits: () => void;
}

function useHomeHooks() : UseHomeHooks {
    const { companies, reload: reloadCompanies } = useCompanies({});
    const { productionUnits, reload: reloadProductionUnits } = useProductionUnits();

    return {
        companies,
        reloadCompanies,
        productionUnits,
        reloadProductionUnits,
    };
}

export { useHomeHooks };