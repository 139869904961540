/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {Observable} from '@babylonjs/core/Misc/observable';
import {SharedAccessSignature} from './SharedAccessSignature';
import {
    ISharedAccessSignatureChunkOnFailure,
    ISharedAccessSignatureChunkOnProgress,
    ISharedAccessSignatureChunkOnRetrying,
    SharedAccessSignatureChunk
} from './SharedAccessSignatureChunk';

export class SharedAccessSignatureBlob {

    public readonly sharedAccessSignature: SharedAccessSignature;

    #_chunksQueue: Array<any> = new Array<number>();
    #_isReadyToUpload: boolean = false;
    #_chunkSize: number = 0;
    #_isChunkUpload: boolean = false;
    #_isCompleted: boolean = false;
    #_chunksUploaded: Record<string, SharedAccessSignatureChunk> = {};

    constructor(sharedAccessSignature: SharedAccessSignature) {
        this.sharedAccessSignature = sharedAccessSignature;

        this.OnCompleted.add((props: ISharedAccessSignatureBlobOnCompleted) => sharedAccessSignature.OnCompleted.notifyObservers(props));
        this.OnFailure.add((props: ISharedAccessSignatureBlobOnFailure) => sharedAccessSignature.OnFailure.notifyObservers(props));
        this.OnProgress.add((props: ISharedAccessSignatureBlobOnProgress) => sharedAccessSignature.OnProgress.notifyObservers(props));
        this.OnRetrying.add((props: ISharedAccessSignatureBlobOnRetrying) => sharedAccessSignature.OnRetrying.notifyObservers(props));

        this.prepare();
    }

    get file() {
        return this.sharedAccessSignature.file;
    }

    get size() {
        return this.sharedAccessSignature.file.size;
    }

    get contentType() {
        return this.sharedAccessSignature.file.type;
    }

    get isReadyToUpload() {
        return this.#_isReadyToUpload;
    }

    get isChunkUpload() {
        return this.#_isChunkUpload;
    }

    get chunksQueue() {
        return this.#_chunksQueue;
    }

    get chunksUploaded() {
        return this.#_chunksUploaded;
    }

    get chunkSize() {
        return this.#_chunkSize;
    }

    getSasUrl = () => {
        const { url: sasUrl } = this.sharedAccessSignature;
        return new URL(sasUrl.toString());
    }

    getBlockList = () => {
        const xmlDoc = document.implementation.createDocument(null, null, null);
        const blockList = xmlDoc.createElement('BlockList');

        Object.values(this.chunksUploaded).forEach((chunk: SharedAccessSignatureChunk) => {
            const latest = xmlDoc.createElement('Latest');
            latest.innerHTML = chunk.chunkId;
            blockList.appendChild(latest);
        });

        xmlDoc.appendChild(blockList);

        return new XMLSerializer().serializeToString(xmlDoc.documentElement);
    }

    getBlockListUrl = () => {
        const { url: sasUrl } = this.sharedAccessSignature;
        const url = new URL(sasUrl.toString());
        url.searchParams.append('comp', 'blockList');

        return url;
    }

    prepare = () => {
        this.calculateChunkSize();

        const chunksQuantity = Math.ceil(this.file.size / this.chunkSize);
        this.#_chunksQueue = new Array(chunksQuantity).fill(true).map((_, index) => index);

        this.#_isReadyToUpload = true;
    }

    calculateChunkSize = () => {
        const { MAXIMUN_SIZE_UNIQUE_UPLOAD, MAXIMUM_BYTES_PER_CHUNK, MINIMUM_BYTES_PER_CHUNK } = SharedAccessSignature;

        this.#_isChunkUpload = this.size > MAXIMUN_SIZE_UNIQUE_UPLOAD;
        this.#_chunkSize = this.isChunkUpload
            ? (this.size >= (MAXIMUN_SIZE_UNIQUE_UPLOAD * 10) ? MAXIMUM_BYTES_PER_CHUNK : MINIMUM_BYTES_PER_CHUNK )
            : MAXIMUN_SIZE_UNIQUE_UPLOAD;
    }

    next = () : SharedAccessSignatureBlob | SharedAccessSignatureChunk | boolean => {
        if (!this.isReadyToUpload) {
            this.prepare();
        }

        if (this.chunksQueue.length === 0) {
            this.OnCompleted.notifyObservers({ blob: this });
            return false;
        }

        const index = this.chunksQueue.shift();

        if (this.isChunkUpload) {
            // if it already exists, it is a retrying
            if (this.chunksUploaded[index]) {
                this.chunksUploaded[index]!.retry();
                return this.chunksUploaded[index]!;
            }

            const begin = index * this.chunkSize;
            const chunk = this.file.slice(begin, begin + this.chunkSize);
            this.chunksUploaded[index] = new SharedAccessSignatureChunk(this, index, chunk);
            this.chunksUploaded[index]!.OnProgress.add(this.progress);
            this.chunksUploaded[index]!.OnCompleted.add(this.chunkCompleted);
            this.chunksUploaded[index]!.OnFailure.add(this.chunkFailed);
            this.chunksUploaded[index]!.OnRetrying.add(this.chunkRetrying);
            return this.chunksUploaded[index]!;
        }

        return this;
    }

    progress = (onProgress: ISharedAccessSignatureChunkOnProgress) => {
        const qty = Object.values(this.chunksUploaded).filter((_) => _.isCompleted).length;

        const totalLoaded = (qty * this.chunkSize) + onProgress.loaded;

        this.OnProgress.notifyObservers({
            loaded: totalLoaded,
            total: this.file.size,
            blob: this,
        });
    }

    chunkCompleted = () => {
        const loaded = Object.values(this.chunksUploaded)
            .filter((_) => _.isCompleted)
            .reduce((previous, current) => previous + current.size, 0);

        this.OnProgress.notifyObservers({
            loaded: loaded,
            total: this.file.size,
            blob: this,
        });
    }

    chunkFailed = (onFailure: ISharedAccessSignatureChunkOnFailure) => {
        this.chunksQueue.unshift(onFailure.chunk.index);
    }

    chunkRetrying = (onRetrying: ISharedAccessSignatureChunkOnRetrying) => {
        this.OnRetrying.notifyObservers({
            chunk: onRetrying.chunk,
            blob: this,
        });
    }

    complete = () => {
        const { size } = this.file;

        this.OnProgress.notifyObservers({loaded: size, total: size, blob: this });

        this.#_isCompleted = true;
        this.OnCompleted.notifyObservers({ blob: this});
    }

    public readonly OnCompleted: Observable<ISharedAccessSignatureBlobOnCompleted> = new Observable<ISharedAccessSignatureBlobOnCompleted>();
    public readonly OnFailure: Observable<ISharedAccessSignatureBlobOnFailure> = new Observable<ISharedAccessSignatureBlobOnFailure>();
    public readonly OnProgress: Observable<ISharedAccessSignatureBlobOnProgress> = new Observable<ISharedAccessSignatureBlobOnProgress>();
    public readonly OnRetrying: Observable<ISharedAccessSignatureBlobOnRetrying> = new Observable<ISharedAccessSignatureBlobOnRetrying>();
}

export interface ISharedAccessSignatureBlobOnCompleted {
    blob: SharedAccessSignatureBlob;
}

export interface ISharedAccessSignatureBlobOnCancelled {
    abortSignal?: AbortSignal;
    error: Error;
    blob: SharedAccessSignatureBlob;
}

export interface ISharedAccessSignatureBlobOnFailure {
    error: Error;
    blob: SharedAccessSignatureBlob;
}
export interface ISharedAccessSignatureBlobOnProgress {
    loaded: number;
    total: number;
    blob: SharedAccessSignatureBlob;
}

export interface ISharedAccessSignatureBlobOnRetrying {
    chunk: SharedAccessSignatureChunk;
    blob: SharedAccessSignatureBlob;
}