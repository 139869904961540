import {useContext} from 'react';
import {FileUploadProgressContext, FileUploadProgressController} from '../components/FileUploadProgress';

interface UseFileUploadProgressProps {
    uploadProgress?: FileUploadProgressController;
}

const useFileUploadProgress = () : UseFileUploadProgressProps => {
    const fileUploadProgressContext = useContext(FileUploadProgressContext);

    return {
        uploadProgress: fileUploadProgressContext?.controller,
    } as UseFileUploadProgressProps;
};

export type { UseFileUploadProgressProps };
export { useFileUploadProgress };