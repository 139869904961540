import {WithTranslation} from 'react-i18next';
import {ClientsVm} from '../../api/vincotte.via.api';
import {Primary as PrimaryButton} from '../../components/NavButtons/Primary';
import ProtectedElement from '../../components/ProtectedElement';
import {Company} from './Company';

interface CompaniesProps extends Pick<WithTranslation, 't'> {
    clients: Array<ClientsVm>;
    onDeleteSuccess?: () => void;
}

function Companies(props: CompaniesProps) {
    const { t, clients, onDeleteSuccess } = props;

    const render = clients.length === 0
        ? (<div>{t('pages.Home.No companies found')}</div>)
        : clients.map((client, k) => <Company t={t} key={k} client={client} onDeleteSuccess={onDeleteSuccess} />);

    return (
        <>
            <div className='row no-gutters pt-5 pb-3' >
                <div className='col-6 pt-2' >
                    <h2>{t('pages.Home.Companies')}</h2>
                </div>

                <ProtectedElement permissions={['CanAddClient']}>
                    <div className='col-6 text-right pr-3' >
                        <PrimaryButton to='/company/add' >{t('pages.Home.New Company')}</PrimaryButton>
                    </div>
                </ProtectedElement>
            </div>

            <div className='row no-gutters' >{render}</div>
        </>
    );
}

Companies.displayName = 'Companies';

export { Companies };
